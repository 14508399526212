function isFilled(event) {
    if(event.target.value == "") {
        event.target.classList.remove('is-filled');
    } else {
        event.target.classList.add('is-filled');
    }
}

document.querySelectorAll('input').forEach(input => {
    input.addEventListener('blur', isFilled);
    input.addEventListener('change', isFilled);
});