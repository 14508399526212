import Component from '../core/Component';
import scrollTo from '../services/Viewport/scrollTo';

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
};

export const NAME = {
    'cs' : 'kontakt',
    'de' : 'kontakte'
}

export default class Contact extends Component {

    prepare() {
        this.lang = document.documentElement.lang

        if(window.location.href.split('#')[1] == NAME[this.lang]){
            this.open(true)
        }
    }

    open(shouldScroll){
        if (shouldScroll){
            scrollTo(this.getTop(this.element))
        }
    }

    getTop(element){
        let box = element.getBoundingClientRect();
        let body = document.body;
        let docEl = document.documentElement;
        let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        let clientTop = docEl.clientTop || body.clientTop || 0;
        let top = box.top +  scrollTop - clientTop;
        return top - 100
    }
}