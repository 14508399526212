import Component from '../core/Component';

export const NAME = {
    'cs' : 'o-nas',
    'de' : 'uber-uns'
}

export default class AboutOpener extends Component {
    prepare() {
        this.lang = document.documentElement.lang
        if(window.location.pathname == '/' || window.location.pathname == '/de/' ) this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        event.preventDefault()
        const aboutElement = document.getElementById('about')
        const about = Component.getFromElement(aboutElement);
        history.replaceState(null, null, '#' + NAME[this.lang]);
        about.open(true)
    }
}