import Component from '../core/Component';

export const NAME = {
    'cs' : 'kontakt',
    'de' : 'kontakte'
}


export default class AboutOpener extends Component {
    prepare() {
        this.lang = document.documentElement.lang
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        event.preventDefault()
        if(window.location.pathname == '/' || window.location.pathname == '/de/') {
            const contactElement = document.getElementById('contact')
            const contact = Component.getFromElement(contactElement);
            history.replaceState(null, null, '#' + NAME[this.lang]);
            contact.open(true);
        }

        else{
            if (this.lang == 'de'){
                window.location.pathname = '/de/'
            }
            else{
                window.location.pathname = '/'
            }
        }
    }
}