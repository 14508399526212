import Component from '../core/Component';

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
};

export default class Nav extends Component {

    constructor(element) {
        super(element);
        this.ref = {
            menu: null, 
            burger: null, 
            links: [],
        }
        this.isOpen = false;

    }

    prepare() {
        this.ref.burger.addEventListener('click', ()=>{
            this.toggle()
        })
        this.ref.links.forEach((element)=>{
            element.addEventListener('click', ()=>{
                this.close()
            })
        })
    }

    open(){
        this.ref.burger.classList.add(STATUS.ACTIVE);
        this.ref.menu.classList.add(STATUS.VISIBLE);
        this.isOpen = true;
    }

    close(){
        this.ref.burger.classList.remove(STATUS.ACTIVE);
        this.ref.menu.classList.remove(STATUS.VISIBLE);
        this.isOpen = false;
    }

    toggle(){
        if (this.isOpen){
            this.close()
        } else {
            this.open()
        }
    }
}