// general
import Antispam from '../../components/Antispam'
import CookieBubble from '../../components/CookieBubble'

// project
import SampleComponent from './SampleComponent'
import About from './About'
import AboutOpener from './AboutOpener'
import Alert from './Alert'
import Contact from './Contact'
import ContactOpener from './ContactOpener'
import FormNewsletter from './FormNewsletter'
import GoogleMap from './GoogleMap'
import HeroCarousel from './HeroCarousel'
import Overlay from './Overlay'
import OverlayOpener from './OverlayOpener'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import Nav from './Nav'
import Notification from './Notification'


const Components = {
    About,
    AboutOpener,
    Alert,
    Contact,
    ContactOpener,
    Antispam,
    CookieBubble,
    GoogleMap,
    FormNewsletter,
    HeroCarousel,
    Overlay,
    OverlayOpener,
    Modal,
    ModalOpener,
    Nav,
    Notification
}

export default Components