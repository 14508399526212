import Component from '../core/Component';
import { enableScrolling, disableScrolling } from '../services/Viewport/scroll';
import support from '../utils/BrowserSupport';
import keycode from 'keycode';
import SimpleBar from 'simplebar';
import enquire from 'enquire.js';
import { queries } from '../core/config';

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
    MODAL_ACTIVE: 'is-modal-active'
};

export const NAME = {
    'cs' : 'obchody-a-sluzby',
    'de' : 'de/geschafte-und-dienstleistungen'
}

export default class Overlay extends Component {
    constructor(element) {
        super(element);
        this.categories = {
            'shops' :  element.querySelectorAll('.stores__list-item--shops'),
            'restaurants' : element.querySelectorAll('.stores__list-item--restaurants'),
            'services' : element.querySelectorAll('.stores__list-item--services'),
            'all' : element.querySelectorAll('.stores__list-item')
        }

        this.select = ::this.select
        this.handleSelect = ::this.handleSelect
        this.handlePopState = ::this.handlePopState
        this.handleKeyUp = ::this.handleKeyUp

        this.selects =  element.querySelectorAll('.stores__link')
        this.stateId = 0;
        this.scrollable = element.querySelector('.stores__scrollable')
        this.list = element.querySelector('.stores__list')
        this.lang = document.documentElement.lang
        this.pathname = window.location.pathname


        this.enquireHandler = {
            unmatch: ::this.close
        }
    }

    prepare() {
        this.closeButton = this.element.querySelector('.stores__close')
        this.closeButton.addEventListener('click', ::this.handleClick);

        this.selects.forEach((element)=>{
            element.addEventListener('click',this.handleSelect)
            let count = element.querySelector('sup.stores__count')
            if(count){
                count.innerText = '(' + this.categories[element.dataset.target].length + ')'
            }
        })
        let simplerbar = new SimpleBar(this.list, { autoHide: false })

        let hash = window.location.href.split('#')[1]

        if(hash){
            this.select(hash)
        }
        enquire.register(queries.largeUp, this.enquireHandler);

    }

    destroy() {
        window.removeEventListener('keyup', ::this.handleKeyUp);
        document.body.classList.remove(STATUS.MODAL_ACTIVE);
        enquire.unregister(queries.largeUp, this.enquireHandler);
    }

    handlePopState(event){
        if (event.state){
            if (event.state.id < (this.stateId + 1)) this.close()
        }
    }

    select(param){

        let categories = {
            'obchody' : 'shops',
            'shops' : 'shops',
            'restaurace' : 'restaurants',
            'restaurants' : 'restaurants',
            'services' : 'services',
            'sluzby' : 'services',
            'all' : 'all',
            'vse' : 'all',
        }

        let category = categories[param]

        if(!category){
            category = 'all'
        }

        for (var key in this.categories){
            this.element.classList.remove(key)
        }
        this.element.classList.add(category)

        const scrollable = this.scrollable

        this.scrollable.innerHTML = ''

        this.categories[category].forEach(function(element){
            scrollable.appendChild(element)
        })

        let url = "#" + param
        this.stateId++
        history.pushState({action: 'change', id : this.stateId}, "stores", url);

        this.selects.forEach((element)=>{
            element.classList.remove('active');
            if(element.classList.contains('stores__link--' + category )) {
                element.classList.add('active')
            }
        })
    }

    handleSelect(event){
        event.preventDefault()
        this.select(event.target.href.split('#')[1]);
    }

    handleClick(event) {
        event.preventDefault();
        this.close();
    }

    handleKeyUp(event) {
        if(keycode(event) === 'esc'){
            this.close()
        }
    }

    open(param){

        disableScrolling();
        this.element.classList.add(STATUS.VISIBLE);
        this.element.offsetWidth;
        this.element.classList.add(STATUS.ACTIVE);
        window.addEventListener('keyup', ::this.handleKeyUp);
        document.body.classList.add(STATUS.MODAL_ACTIVE);

        this.stateId++

        if (param){
            this.select(param)
            let url = "/" + NAME[this.lang] + "#" + param
            history.pushState({action: 'open', id : this.stateId}, "stores", url);
        }else{
            this.select('all')
            let url = "/" + NAME[this.lang]

            history.pushState({action: 'open', id : this.stateId}, "stores", url);
        }

        window.addEventListener('popstate', this.handlePopState);


    }

    close() {
        enableScrolling();
        this.element.classList.remove(STATUS.ACTIVE);
        setTimeout(()=>{
            this.element.classList.remove(STATUS.VISIBLE);
        }, 1000)

        window.removeEventListener('popstate', this.handlePopState);
        window.removeEventListener('keyup', ::this.handleKeyUp);

        document.body.classList.remove(STATUS.MODAL_ACTIVE);


        history.pushState({action: 'open', id : this.stateId}, "stores", this.pathname);
    }




}