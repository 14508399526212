import Component from '../core/Component';
import GoogleMapsLoader from 'google-maps';
import $ from 'jquery';
import debounce from 'lodash/debounce';
import { createMarker } from './Marker'
GoogleMapsLoader.KEY = 'AIzaSyCZywuF-VoGRAcySDmTIhtX_lNMk5QRSrQ';

export default class GoogleMap extends Component {

    constructor($element) {
        super($element);

        this.latlng = null;
        this.isReady = false;

        this.$win = $(window);
    }

    prepare() {
        this.coords =  {
            lat: this.element.dataset.lat,
            lng: this.element.dataset.lng
        }

        GoogleMapsLoader.load(::this.render);

        this.$win.on('resize.googleMap orientationchange.googleMap', debounce(::this.center, 200));
    }

    render(google) {
        const latlng = new google.maps.LatLng(this.coords.lat, this.coords.lng);
        this.google = google;
        this.latlng = latlng;

        this.map = new google.maps.Map(this.element, {
            center: latlng,
            zoom: 10,
            mapTypeId: google.maps.MapTypeId.ROADMAP, 
            zoomControl: false,
            scaleControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: [ { "featureType": "administrative.land_parcel", "stylers": [ { "visibility": "off" } ] }, { "featureType": "administrative.neighborhood", "stylers": [ { "visibility": "off" } ] }, { "featureType": "poi", "elementType": "labels.text", "stylers": [ { "visibility": "off" } ] }, { "featureType": "poi.business", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road.arterial", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road.highway", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road.local", "stylers": [ { "visibility": "off" } ] }, { "featureType": "transit", "stylers": [ { "visibility": "off" } ] }, { "featureType": "water", "elementType": "labels.text", "stylers": [ { "visibility": "off" } ] } ]
        });
        const Marker = createMarker(google);

        var marker = new Marker({
            position: latlng,
            map: this.map
        });

        setTimeout(() => {
            this.isReady = true;
            this.center();
        }, 0);
    }

    center() {
        if (this.isReady) {
            this.google.maps.event.trigger(this.map,'resize');
            this.map.setCenter(this.latlng);
        }
    }

    destroy() {
        this.$element.detach();
        this.map = null;
        this.$win.off('.googleMap');
    }
}
