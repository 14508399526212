import Component from '../core/Component';
import scrollTo from '../services/Viewport/scrollTo';

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
};
export const NAME = {
    CS : 'o-nas',
    DE : 'uber-uns'
}

export default class About extends Component {
    constructor(element) {
        super(element);
        this.moreContent = element.querySelector('.about-us-more')
        this.button = element.querySelector('.button--hollow');
    }

    prepare() {

        let url = window.location.href.split('#')[1]

        if( url == NAME.CS || url == NAME.DE){
            this.open(true)
        }
    }

    open(shouldScroll){
        this.moreContent.classList.add(STATUS.VISIBLE);
        this.moreContent.offsetWidth;
        this.moreContent.classList.add(STATUS.ACTIVE);
        this.element.classList.add('button-hidden')
        if (shouldScroll){
            scrollTo(this.getTop(this.element))
        }
    }
    getTop(element){
        let box = element.getBoundingClientRect();
        let body = document.body;
        let docEl = document.documentElement;
        let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        let clientTop = docEl.clientTop || body.clientTop || 0;
        let top = box.top +  scrollTop - clientTop;
        return top
    }
}