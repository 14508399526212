import Component from '../core/Component';
import Flickity from 'flickity-sync';

export default class HeroCarousel extends Component {
    
    constructor(element) {
        super(element);
        this.ref = {
            dots: [],
            content: null,
            slides: [],
            next: null,
        }
    }
    
    prepare() {
        
        this.flickity = new Flickity(this.ref.content, {
            cellSelector: '.hero__flickity-item',
            cellAlign: 'left',
            prevNextButtons: false,
            resize: true,
            pageDots: false,
            contain: true,
            draggable: '>1',
            wrapAround: true,
            autoPlay: 3000,
        });

        this.flickity.on('change', (index) => {
            this.ref.dots.forEach(dot => {
                dot.classList.remove('is-active');
            });
            this.ref.dots[index].classList.add('is-active');
        });

        this.flickity.on('staticClick', function(event, pointer, cellElement, cellIndex){
            this.next();
        });

        $(this.ref.dots).on('click', (event)=> {
            this.flickity.selectCell(this.ref.slides[this.ref.dots.indexOf(event.target)]);
        });
        
        $(this.ref.next).on('click', (event)=> {
            this.flickity.next();
        });

    }
}