import Component from '../core/Component';
import enquire from 'enquire.js';
import { queries } from '../core/config';


export default class OverlayOpener extends Component {
    constructor(element){
        super(element)

        this.isMobile = true;

        this.enquireHandler = {
            match: () => this.isMobile = false,
            unmatch: () => this.isMobile = true,
        }

    }
    prepare() {
        enquire.register(queries.largeUp, this.enquireHandler);

        this.element.addEventListener('click', ::this.handleClick);
        this.target = document.getElementById('stores');
        this.overlay = Component.getFromElement(this.target);
    }

    destroy() {
        enquire.unregister(queries.largeUp, this.enquireHandler);
    }

    handleClick(event) {
        if(!this.isMobile){
            event.preventDefault();
            if (this.target) {
                const param = this.element.hash.split('#')[1]
                if (param && this.overlay){
                    this.overlay.open(param);
                }
                else if(this.overlay){
                    this.overlay.open('');
                }
            }
        }
    }


}