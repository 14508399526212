import scrollToElement from '../../services/Viewport/scrollToElement';
import $ from 'jquery';

document.addEventListener('nette:valid', event => {
    const element = event.target;

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = element.parentNode.querySelector('.input__error');
        if (errorElement) {
            element.parentNode.removeChild(errorElement);
        }
    }

    element.classList.remove('has-error');
    element.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    const element = event.target;

    let errorElement = element.parentNode.querySelector('.input__error');

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'input__error';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    element.parentNode.appendChild(errorElement);
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => console.info('Form valid', event.target));
document.addEventListener('nette:form:invalid', event => {
    const $form = $(event.target);
    const $error = $form.find('.input__error');
    let $target = $error.closest('.input');
    scrollToElement($target.length ? $target : $form, -120);
});